<template>
  <v-card flat class="rounded-10">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="180" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          >
          </v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card flat width="180" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          >
          </v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p class="text-center secondary--text col-md-8 mx-auto fs-20 py-5">
          Integration with {{ connectApp.name }} will have access to following
          functionality:
        </p>
        <ul class="col-md-6 secondary--text mx-auto fs-17">
          <li>
            Auto generations of leads from
            {{ connectApp.name }} conversations
          </li>
          <li>More capabilities coming soon.</li>
        </ul>
      </v-row>
      <v-row dense class="mb-1">
        <h1
          class="
            text-center
            primary--text
            col-md-8
            mx-auto
            fs-25
            font-weight-bold
            py-5
          "
        >
          Setup Instructions
        </h1>
        <v-card flat class="col-md-8 col-sm-12 mx-auto">
          <v-card-text>
            <h2 class="primary--text font-weight-bold fs-16">
              Step 1 : Generate Webhook URL
            </h2>
            <div class="pa-2 whitesmoke">
              <strong class="d-flex my-3">
                Generate a webhook URL for MobileMonkey in Leads &nbsp;
                <a class="text-none" @click="goToLeads"> here </a>
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="require('@/assets/tutorials/mobilemonkey-start.jpg')"
              >
              </v-img>
            </div>
            <v-divider class="my-2"> </v-divider>
            <h2 class="primary--text font-weight-bold fs-16">
              Step 2 : Setup Webhook on MobileMonkey
            </h2>
            <div class="pa-2 whitesmoke">
              <strong class="d-flex my-3">
                2.1. Login to your MobileMonkey Account and go to &nbsp;
                Integration &nbsp;> &nbsp; JSON Webhooks configuration page.
                (You may need some MobileMonkey Unicorn subscription for this)
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="require('@/assets/tutorials/mobilemonkey-webhook.png')"
              >
              </v-img>
              <strong class="d-flex my-3">
                2.2. Click "Add a connection" button (a popup will show about
                using Zapier, click &nbsp; <b> "No, I'm not" </b> )
              </strong>
              <strong class="d-flex my-3">
                2.3. Set Title and change status to &nbsp; <b> On </b>
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-2.png')"
              >
              </v-img>
              <strong class="d-flex my-3">
                2.3. For connection type, select "Send contact data whenever a
                widget is reached"
              </strong>
              <strong class="d-flex my-3">
                2.4. For request type, select POST and paste the generated URL
                to URL field <br />
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-3.png')"
              >
              </v-img>
              <strong class="d-flex my-3">
                2.5. Set attributes to be sent to {{ settings.SiteName }}. See
                below image for example
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-4.png')"
              >
              </v-img>
            </div>
            <v-divider class="my-3"> </v-divider>
            <h2 class="primary--text font-weight-bold fs-16">
              Step 3 : Use the connection in your dialogues
            </h2>
            <div class="pa-2 mt-4 whitesmoke">
              <strong class="d-flex my-3">
                3.1. In your dialogue add a widget.
              </strong>
              <v-img
                class="my-1 mx-auto"
                :max-width="mdAndUp ? '80%' : '100%'"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-1.png')
                "
              >
              </v-img>
              <strong class="d-flex my-3"> 3.2. Select More </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-2.png')
                "
              >
              </v-img>
              <strong class="d-flex my-3">
                3.3. From connection widget, select Zap/Connection
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-3.png')
                "
              >
              </v-img>
              <strong class="d-flex my-3">
                3.4. Select you connection from Step 2
              </strong>
              <v-img
                :max-width="mdAndUp ? '80%' : '100%'"
                class="my-1 mx-auto"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-4.png')
                "
              >
              </v-img>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects', 'allowedModules']),
    connectApp() {
      return this.connects.find((i) => i.id === 'mobilemonkey')
    },
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    }
  },
  methods: {
    goToLeads() {
      if (!this.allowedModules.includes('leads')) {
        this.appSnackbar(
          'Leads feature is currently unavailable based on your subscriptions',
          'error'
        )
      } else {
        this.$router.push({ name: 'leads' })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
